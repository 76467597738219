import { UPDATE_USER } from '../constants/user';

const userReducer = (state = {}, action) => {
  let clonedState;

  switch (action.type) {
    case UPDATE_USER:
      clonedState = JSON.parse(JSON.stringify(state));
      clonedState = action.user;

      return clonedState;

    default:
      return state;
  }
}

export default userReducer;
