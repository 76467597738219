import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button } from '@mui/material';

const mapStateToProps = (state) => {
  return {
    board: state.board,
  };
};

const LoginButton = ({ board }) => {
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate(`/login/${board.id}`);
  }

  return (
    <Button variant="contained" color="secondary" onClick={handleLogin} sx={{ flex: 1 }}>
      Login
    </Button>
  );
};

export default connect(
  mapStateToProps,
)(LoginButton);
