import React from 'react';

const DrawerDashboard = ({ content, users }) => {
  return (
    <>
    </>
  );
};

export default DrawerDashboard;
