
import { CLOSE_DRAWER, OPEN_DRAWER } from '../constants/drawer';

const closeDrawer = () => {
  return { type: CLOSE_DRAWER };
};

const openDrawer = () => {
  return { type: OPEN_DRAWER };
};

export { closeDrawer, openDrawer }
