
import React from 'react';

import Typography from '@mui/material/Typography';

const DashboardTitle = ({ title }) => {

  // @TODO: not the best place I guess
  document.title = title;

  return (
    <Typography className="boardTitle" variant="h6">
      {title}
    </Typography>
  );
};

export default DashboardTitle;
