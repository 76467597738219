import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';

import { toggleExport } from '../actions/export';


const mapStateToProps = (state) => {
  return {
    cards: state.cards,
    columns: state.columns,
    isExportOpen: state.board.isExportOpen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeExport: () => dispatch(toggleExport(false)),
  };
}

const Export = ({ cards, closeExport, columns, isExportOpen }) => {
  const [format, setFormat] = useState('text');
  const [output, setOutput] = useState('');

  const [state, setState] = useState({
    removeEmojis: false,
  });

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };

  const copyToClipboard = () => {
    // #1. Use an input element
    const textarea = document.querySelector('.exportTextfield textarea');

    // #2. Set the input's value to the text we want to copy to clipboard
    // textarea.value = 'hello there!';

    // #3. Highlight the input's value
    textarea.select();

    // #4. Copy the highlighted text
    document.execCommand('copy');
  };

  const handleChangeFormat = (event) => {
    setFormat(event.target.value);
  };

  const handleCloseExport = () => {
    closeExport();
  };

  const removeEmojis = (text) => {
    return text.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, '');
  }

  useEffect(() => {
    const processContent = (content) => {
      if (state.removeEmojis) {
        content = removeEmojis(content);
      }

      return content;
    }

    const generateOutput = (formatOutput, columnOutput, columnIndexOutput) => {
      const clonedColumns = JSON.parse(JSON.stringify(columns));
      let generatedOutput = '';

      if (formatOutput === 'confluence-list') {
        generatedOutput += `${columnIndexOutput > 0 ? '\n' : ''}h3. ${clonedColumns[columnIndexOutput].title}\n\n`;
        columnOutput.forEach((card) => {
          generatedOutput += `- *${card.userName || 'Anonymous'}*: ${processContent(card.content)} (${card.claps} claps)\n`;
        });
      }

      if (formatOutput === 'confluence-table') {
        generatedOutput += `${columnIndexOutput > 0 ? '\n' : ''}h3. ${clonedColumns[columnIndexOutput].title}\n\n`;
        generatedOutput += '||User||Content||Claps||\n';
        columnOutput.forEach((card) => {
          generatedOutput += `|${card.userName || 'Anonymous'}|${processContent(card.content)}|${card.claps}|\n`;
        });
      }

      if (formatOutput === 'csv') {
        columnOutput.forEach((card) => {
          generatedOutput += `${clonedColumns[columnIndexOutput].title};${card.userName || 'Anonymous'};${processContent(card.content)};${card.claps}\n`;
        });
      }

      if (formatOutput === 'markdown') {
        generatedOutput += `${columnIndexOutput > 0 ? '\n' : ''}### ${clonedColumns[columnIndexOutput].title}\n\n`;
        columnOutput.forEach((card) => {
          generatedOutput += `- [${card.userName || 'Anonymous'}] ${processContent(card.content)} (${card.claps} claps)\n`;
        });
      }

      if (formatOutput === 'text') {
        generatedOutput += `${columnIndexOutput > 0 ? '\n' : ''}${clonedColumns[columnIndexOutput].title}\n\n`;
        columnOutput.forEach((card) => {
          generatedOutput += `[${card.userName || 'Anonymous'}] ${processContent(card.content)} (${card.claps} claps)\n`;
        });
      }

      return generatedOutput;
    };

    const clonedCard = JSON.parse(JSON.stringify(cards));
    let tmpOutput = '';
    let columnIndex = 0;

    columns.forEach((column) => {
      if (clonedCard[column.id]) {
        // 1. Sort cards by claps descending
        clonedCard[column.id].sort((a, b) => {
          return b.claps - a.claps;
        });
        // 2. Generate the output
        tmpOutput += generateOutput(format, clonedCard[column.id], columnIndex);
        // 3. Next column
      }
      columnIndex++;
    });

    setOutput(tmpOutput);
  }, [format, cards, columns, state.removeEmojis]);

  return (
    <Dialog
      // maxWidth="lg"
      fullScreen
      onClose={handleCloseExport}
      open={isExportOpen}
      // TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton color="inherit" onClick={handleCloseExport} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography variant="h6">
            Export
          </Typography>
        </Toolbar>
      </AppBar>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', padding: '24px' }}>
        <div>
          <FormControl sx={{ marginBottom: '24px' }}>
            <Select
              value={format}
              onChange={handleChangeFormat}
              sx={{ minWidth: '200px'}}
            >
              <MenuItem value="confluence-list">Confluence list</MenuItem>
              <MenuItem value="confluence-table">Confluence table</MenuItem>
              <MenuItem value="csv">CSV</MenuItem>
              <MenuItem value="markdown">Markdown</MenuItem>
              <MenuItem value="text">Text</MenuItem>
            </Select>
          </FormControl>
          <FormControlLabel
            control={
              <Checkbox
                checked={state.removeEmojis}
                onChange={handleChange}
                name="removeEmojis"
                color="primary"
              />
            }
            label="Remove emojis"
            sx={{ marginLeft: '24px' }}
          />
        </div>
        <TextField
          fullWidth
          multiline
          size="small"
          value={output}
          variant="filled"
          // rowsMax={20}
          className="exportTextfield"
        />
        <Button autoFocus variant="contained" color="primary" onClick={copyToClipboard} sx={{ alignSelf: 'flex-start', marginTop: '24px' }}>
          copy
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Export);
