import React from 'react';
import { connect } from 'react-redux';

import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import ImportExportIcon from '@mui/icons-material/ImportExport';

import { closeDrawer } from '../../actions/drawer';
import { toggleExport } from '../../actions/export';

const mapDispatchToProps = (dispatch) => {
  return {
    closeDrawer: () => dispatch(closeDrawer()),
    openExport: () => dispatch(toggleExport(true)),
  };
}

const ListItemExport = ({ closeDrawer, openExport }) => {
  const handleExport = () => {
    closeDrawer();
    openExport();
  }

  return (
    <ListItem button onClick={handleExport}>
      <ListItemIcon>
        <ImportExportIcon />
      </ListItemIcon>
      <ListItemText primary="Export" />
    </ListItem>
);
};

export default connect(
  null,
  mapDispatchToProps,
)(ListItemExport);
