import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';

import { socket } from '../config';
import {
  sortCards as sortCardsDispatch,
  updateCard as updateCardDispatch,
} from '../actions/card';
import Unlike from './Unlike';
import UnlikeNew from './UnlikeNew';

const mapStateToProps = (state) => {
  return {
    board: state.board,
    likes: state.likes,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sortCards: (payload) => dispatch(sortCardsDispatch(payload)),
    updateCard: (payload) => dispatch(updateCardDispatch(payload)),
  };
}

const Like = ({ board, card, likes, sortCards, updateCard, user }) => {
  const isOldLike = board.createdAt < '2022-02-12'; // Switch from multiple thumbs to one single heart
  const isNewLike = !isOldLike;

  const cardLikes = likes.filter((like) => like.cardId === card.id);
  const [disabled, setDisabled] = useState(false);

  const handleLike = () => {
    if (cardLikes.length >= 4) {
      setDisabled(true);
    }

    if (cardLikes.length >= 5) {
      return false;
    }

    updateCard({
      boardId: card.boardId,
      cardId: card.id,
      columnId: card.columnId,
      claps: card.claps + 1,
    });

    const sortBy = localStorage.getItem('sortBy') || 'ASC';
    sortCards(sortBy);

    socket.emit('clap::add', {
      card: card,
      userId: user.id,
    });
  }

  useEffect(() => {
    if (cardLikes.length >= 5) {
      setDisabled(true);
    } else  {
      setDisabled(false);
    }
  }, [cardLikes.length]);

  return (
    <>
      {isOldLike &&
        <Tooltip title="Like">
          <span>
            <IconButton size="small" onClick={handleLike} disabled={disabled}>
              <ThumbUpIcon fontSize="inherit" />
            </IconButton>
          </span>
        </Tooltip>
      }
      {isNewLike && cardLikes.length === 0 &&
        <Tooltip title="Like">
          <span>
            <IconButton size="small" onClick={handleLike} disabled={disabled}>
              <FavoriteBorderIcon fontSize="inherit" />
            </IconButton>
          </span>
        </Tooltip>
      }
      {isNewLike && cardLikes.length === 1 &&
        cardLikes.map((like) => <UnlikeNew key={like.id} card={card} like={like} />)
      }
      <Typography variant="caption" color="textSecondary" sx={{ marginLeft: '5px', marginRight: '5px', wordBreak: 'keep-all' }}>
        {card.claps}
      </Typography>
      {isOldLike &&
        cardLikes.map((like) => <Unlike key={like.id} card={card} like={like} />)
      }
    </>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Like);
