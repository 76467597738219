import React, { useState, useEffect } from 'react';

import Avatar from '@mui/material/Avatar';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import DashboardIcon from '@mui/icons-material/Dashboard';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';

import Template from './Template';
import { startNewBoard } from '../utils';

const Dashboard = () => {
  let [boards, setBoards] = useState([]);
  let [isLoaded, setIsLoaded] = useState(false);

  const handleRemove = (board) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ boardId: board.id }),
    };

    fetch('/api/remove', requestOptions)
      .then((response) => response.json())
      .then((res) => {
        setBoards(res);
      });
  }

  const handleClick = (board) => {
    window.location.href = `/board/${board.id}`;
  };

  useEffect(() => {
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    };

    fetch('/api/dashboard', requestOptions)
      .then((response) => response.json())
      .then((res) => {
        setBoards(res);
        setIsLoaded(true);
      });
  }, []);

  const line = (board) => {
    return (
      <ListItem button key={board.id} onClick={() => handleClick(board.board)}>
        <ListItemIcon>
          <Avatar>
            <DashboardIcon />
          </Avatar>
        </ListItemIcon>
        <ListItemText primary={board.board && board.board.title} />
        <ListItemSecondaryAction>
          <Tooltip title="Remove" placement="left">
            <IconButton edge="end" aria-label="remove" onClick={() => handleRemove(board.board)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </ListItemSecondaryAction>
      </ListItem>
    );
  };

  return (
    <Template template="Dashboard">
      <div className="dashboard">
        <Paper elevation={1} style={{ display: isLoaded && boards.length ? 'block' : 'none' }} sx={{ margin: 32 }}>
          <List>
            {boards.map((board) => line(board))}
          </List>
        </Paper>
        <Typography style={{ display: isLoaded && !boards.length ? 'block' : 'none' }} sx={{ margin: 32 }}>
          You didn't joined any board yet. <Link onClick={startNewBoard} sx={{ cursor: 'pointer' }}>Create a new board</Link>
        </Typography>
      </div>
    </Template>
  );
};

export default Dashboard;
