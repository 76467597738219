import React from 'react';
import { connect } from 'react-redux';

import Drawer from '@mui/material/Drawer';

import DrawerContent from './DrawerContent';
import {
  closeDrawer as closeDrawerDispatch,
} from '../../actions/drawer';

import './DrawerContent.css';
import useBreakpoints from '../../hooks/useBreakpoints';

const mapStateToProps = (state) => {
  return {
    isDrawerOpen: state.board.isDrawerOpen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeDrawer: () => dispatch(closeDrawerDispatch()),
  };
}

const DrawerContainer = ({ closeDrawer, isDrawerOpen, template }) => {
  const { isMobile, isDesktop } = useBreakpoints();

  return (
    <>
      {isMobile &&
        <Drawer open={isDrawerOpen} onClose={closeDrawer}>
        <DrawerContent template={template} />
      </Drawer>
      }
      {isDesktop &&
        <Drawer open={isDrawerOpen} onClose={closeDrawer} variant="permanent">
          <DrawerContent template={template} />
        </Drawer>
      }
    </>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DrawerContainer);
