import React from 'react';
import { connect } from 'react-redux';

import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AddIcon from '@mui/icons-material/Add';

import { socket } from '../../config';
import { closeDrawer } from '../../actions/drawer';

const mapStateToProps = (state) => {
  return {
    boardId: state.board.id,
    columns: state.columns,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeDrawer: () => dispatch(closeDrawer()),
  };
}

const AddColumn = ({ boardId, closeDrawer, columns }) => {
  const handleAddColumn = () => {
    closeDrawer();

    socket.emit('column::add', {
      boardId: boardId,
      index: columns.length,
    });
  }

  return (
    <ListItem button onClick={handleAddColumn}>
      <ListItemIcon>
        <AddIcon />
      </ListItemIcon>
      <ListItemText primary="Add column" />
    </ListItem>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddColumn);
