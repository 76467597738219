import { connect } from 'react-redux';

import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';

import {
  openDrawer as openDrawerDispatch,
} from '../../actions/drawer';

const mapDispatchToProps = (dispatch) => {
  return {
    openDrawer: () => dispatch(openDrawerDispatch()),
  };
}

const IconDrawer = ({ openDrawer }) => {
  return (
    <IconButton
      color="inherit"
      edge="start"
      onClick={openDrawer}
      aria-label="Open drawer"
    >
      <MenuIcon />
    </IconButton>
  );
}

export default connect(
  null,
  mapDispatchToProps,
)(IconDrawer);
