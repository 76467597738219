import { connect } from 'react-redux';

import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import PeopleIcon from '@mui/icons-material/People';

import AddColumn from './AddColumn';
import Duplicate from './Duplicate';
import ListItemExport from './ListItemExport';
import Share from './Share';

import LoginButton from './LoginButton';

const mapStateToProps = (state) => {
  return {
    user: state.user,
    users: state.board.users,
  };
};

const DrawerDashboard = ({ user, users }) => {
  const displayUsers = () => {
    const formattedUsers = {};
    let outputArr = [];

    users.forEach((user) => {
      if (formattedUsers[user]) {
        formattedUsers[user]++;
      } else {
        formattedUsers[user] = 1;
      }
    });

    for (let user in formattedUsers) {
      if (formattedUsers.hasOwnProperty(user)) {
        if (formattedUsers[user] > 1) {
          outputArr.push(`${user} (${formattedUsers[user]})`);
        } else {
          outputArr.push(`${user}`);
        }
      }
    }

    return outputArr.join(', ');
  };

  return (
    <>
      {user.isAnonymous &&
        <ListItem>
          <LoginButton />
        </ListItem>
      }
      <ListItem>
        <ListItemIcon>
          <PeopleIcon />
        </ListItemIcon>
        <ListItemText primary={`${users.length} user${users.length > 1 ? 's' : ''}`} />
      </ListItem>
      <ListItem>
        <Typography variant="caption" display="block" gutterBottom>
          {displayUsers()}
        </Typography>
      </ListItem>
      <Divider />
      <AddColumn />
      <ListItemExport />
      <Share />
      <Duplicate />
    </>
  );
};

export default connect(
  mapStateToProps,
)(DrawerDashboard);
