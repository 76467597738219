
import React from 'react';
import { connect } from 'react-redux';
import { Draggable, Droppable } from 'react-beautiful-dnd';

import TextField from '@mui/material/TextField';

import { socket } from '../config';
import Card from './Card';
import ColumnTitle from './ColumnTitle';
import DeleteButton from './DeleteButton';

const mapStateToProps = (state) => {
  return {
    boardId: state.board.id,
    cards: state.cards,
  };
};

const Column = ({ boardId, column, cards }) => {
  const handleNewCard = (event, columnId) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      handleSubmit(event, columnId);
    }
  };

  const handleSubmit = (event, columnId) => {
    event.preventDefault();

    const content = event.target.value.trim();
    // Empty the current input text
    event.target.value = '';

    // Don't add an empty card
    if (content.length === 0) {
      return;
    }

    const card = {
      boardId: boardId,
      columnId: columnId,
      content: content,
      index: cards[columnId] ? cards[columnId].length : 0,
    };

    socket.emit('card::add', card);
  };

  const renderCard = (card, index) => {
    return (
      <Draggable draggableId={card.id} key={card.id} index={index}>
        {(provided) => {
          provided.dragHandleProps.tabIndex = -1;
          return (
            <div
              className="c"
              key={card.id}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              ref={provided.innerRef}
            >
              <Card key={card.id} card={card} />
            </div>
          )
        }}
      </Draggable>
    );
  };

  return (
    <div id={column.id} key={column.id} className="column">
      <form className="formInput" onSubmit={(e) => handleSubmit(e, column.id)}>
        <ColumnTitle
          column={column}
        />
        <TextField
          fullWidth={true}
          multiline
          placeholder="Type a card..."
          variant="outlined"
          onKeyPress={(event) => handleNewCard(event, column.id)}
        />
      </form>

      <Droppable droppableId={column.id} key={column.id} isCombineEnabled>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            id={`droppable${column.id}`}
          >
            {cards && cards[column.id] && cards[column.id].map((card, cardIndex) => {
              return (
                renderCard(card, cardIndex)
              )
            })}
            <DeleteButton
              cards={cards[column.id]}
              columnId={column.id}
            />
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>  );
};

export default connect(
  mapStateToProps,
)(Column);
