import Typography from '@mui/material/Typography';

import Template from './Template';

const Error = () => {
  return (
    <Template template="Error">
      <div className="form">
        <Typography variant="h3">
          Error
        </Typography>
      </div>
    </Template>
  );
};

export default Error;
