import React from 'react';

import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import CreateIcon from '@mui/icons-material/Create';

import { startNewBoard } from '../../utils';

const ListItemNew = () => {
  return (
    <ListItem button onClick={startNewBoard}>
      <ListItemIcon>
        <CreateIcon />
      </ListItemIcon>
      <ListItemText primary="New board" />
    </ListItem>
  );
};

export default ListItemNew;
