
import React from 'react';
import { connect } from 'react-redux';

import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

import { socket } from '../config';
import { deleteColumn as deleteColumnAction } from '../actions/column';

const mapStateToProps = (state) => {
  return {
    boardId: state.board.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteColumn: (columnId) => dispatch(deleteColumnAction(columnId)),
  };
}

const DeleteButton = ({ boardId, cards, columnId, deleteColumn }) => {
  const handleClick = () => {
    // Delete the column for the user
    deleteColumn(columnId);

    // Send the column to delete to all other users
    socket.emit('column::delete', {
      boardId: boardId,
      columnId: columnId,
    });
  };

  if (!cards || cards.length === 0) {
    return (
      <IconButton
        onClick={handleClick}
        size="small"
        style={{ display: 'flex', margin: 'auto' }}
      >
        <DeleteIcon fontSize="inherit" />
      </IconButton>
    );
  } else {
    return null;
  }
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeleteButton);
