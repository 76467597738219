
import { ADD_CARD, DELETE_CARD, INIT_CARDS, MOVE_CARD, SORT_CARDS, UPDATE_CARD } from '../constants/card';

const addCard = (card) => {
  return { type: ADD_CARD, card };
};

const deleteCard = (card) => {
  return { type: DELETE_CARD, card };
};

const initCards = (cards) => {
  return { type: INIT_CARDS, cards };
};

const moveCard = (payload) => {
  return { type: MOVE_CARD, payload };
};

const sortCards = () => {
  return { type: SORT_CARDS };
};

const updateCard = (payload) => {
  return { type: UPDATE_CARD, payload };
};

export { addCard, deleteCard, initCards, moveCard, sortCards, updateCard }
