import pkg from '../package.json';

import { io } from 'socket.io-client';

const config = {
  development: {
    endpoint: pkg.proxy,
  },
  production: {
    endpoint: window.location.hostname === 'localhost' ? pkg.proxy : window.location.hostname,
  },
  test: {
    endpoint: pkg.proxy,
  },
}

const socket = io(config[process.env.NODE_ENV].endpoint, {
  withCredentials: true,
});

export { config, socket };
