import React from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';

import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import DashboardIcon from '@mui/icons-material/Dashboard';

import { closeDrawer } from '../../actions/drawer';

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeDrawer: () => dispatch(closeDrawer()),
  };
};

const ListItemDashboard = ({ closeDrawer, user }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    closeDrawer();
    navigate('/dashboard');
  }

  const Dashboard = () => {
    return (
      <ListItem button onClick={handleClick}>
        <ListItemIcon>
          <DashboardIcon />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItem>
    );
  }

  return user.isAnonymous ? <></> : <Dashboard />;
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ListItemDashboard);
