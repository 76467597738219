import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import BoardTitle from './BoardTitle';
import DashboardTitle from './DashboardTitle';
import IconDrawer from './IconDrawer';
import Sort from './Sort';
import useBreakpoints from '../../hooks/useBreakpoints';

const AppBarSB = ({ template }) => {
  const { isMobile } = useBreakpoints();
  return (
    <AppBar position="fixed" style={{ zIndex: 1201 }}>
      <Toolbar>
        {isMobile && <IconDrawer />}

        {template === 'Dashboard' && <DashboardTitle title="Dashboard" />}
        {template === 'Error' && <DashboardTitle title="Error" />}
        {template === 'Login' && <DashboardTitle title="Login" />}
        {template === 'Profile' && <DashboardTitle title="Profile" />}
        {template === 'Register' && <DashboardTitle title="Register" />}
        {template === 'Reset' && <DashboardTitle title="Reset password" />}

        {template === 'Board' && <BoardTitle />}
        {/* {template === 'Board' && <NotificationUsername />} */}
        {template === 'Board' && <Sort />}
      </Toolbar>
    </AppBar>
  );
}

export default AppBarSB;
