
import { useEffect } from 'react';
import { connect } from 'react-redux';

import Typography from '@mui/material/Typography';

import { socket } from '../../config';
import './BoardTitle.css';

import { setBoardTitle as setBoardTitleAction } from '../../actions/board';

const mapStateToProps = (state) => {
  return {
    board: state.board,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setBoardTitle: (boardTitle) => dispatch(setBoardTitleAction(boardTitle)),
  };
}

const BoardTitle = ({ board, setBoardTitle }) => {
  const handleTitle = (event) => {
    const newTitle = event.target.textContent;

    if (event.type === 'keypress') {
      if (event.key === 'Enter') {
        event.target.blur();
      } else {
        return;
      }
    }

    setBoardTitle(newTitle);

    socket.emit('board::title::update', {
      boardId: board.id,
      title: newTitle,
    });
  };

  useEffect(() => {
    document.title = board.title;
  }, [board.title]);

  return (
    <Typography
      className="boardTitle single-line"
      contentEditable
      onBlur={handleTitle}
      onKeyPress={handleTitle}
      suppressContentEditableWarning={true}
      variant="h6"
    >
      {board.title}
    </Typography>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BoardTitle);
