import React, { useState } from 'react';

import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Snackbar from '@mui/material/Snackbar';

import ShareIcon from '@mui/icons-material/Share';

import './Share.css';

const Share = (props) => {
  const [isSnackbarOpen, toggleSnackbar] = useState(false);

  const handleShare = () => {
    // #1. Use an input element
    const textarea = document.querySelector('#shareUrl');

    textarea.style.display = 'block';

    // #2. Set the input's value to the text we want to copy to clipboard
    textarea.value = document.location.href;

    // #3. Highlight the input's value
    textarea.select();

    // #4. Copy the highlighted text
    document.execCommand('copy');

    textarea.style.display = 'none';

    toggleSnackbar(true);
  }

  return (
    <>
      <ListItem button onClick={handleShare}>
        <ListItemIcon>
          <ShareIcon />
        </ListItemIcon>
        <ListItemText primary="Share board" />
        <input type="text" id="shareUrl"></input>
      </ListItem>
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={isSnackbarOpen}
        onClose={() => toggleSnackbar(false)}
        autoHideDuration={3000}
        message="URL copied, you can now share it with your team!"
      />
    </>
  );
};

export default Share;
