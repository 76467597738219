import { useState } from 'react';

import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';

import DeleteIcon from '@mui/icons-material/Delete';
import ImageIcon from '@mui/icons-material/Image';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import './Card.css';
import Giphy from './Giphy';

const CardMenu = ({ card, handleEdit, setIsMouseOver }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isGiphyOpen, setIsGiphyOpen] = useState(false);
  const open = Boolean(anchorEl);

  const deleteCard = () => {
    const event = {
      target: {
        value: '',
      },
    };

    handleEdit(event);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const handleGiphy = (selectedGiphy) => {
    const event = {
      target: {
        value: card.content,
      },
      giphy: selectedGiphy,
    };

    handleEdit(event);
    // Hide the 3 dots icon + delete Giphy icon
    setIsMouseOver();
  };

  const openGiphy = () => {
    setIsGiphyOpen(true);
    closeMenu();
  };

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <IconButton
        onClick={openMenu}
        size="small"
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <MoreVertIcon fontSize="inherit" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        disableAutoFocusItem
        keepMounted
        onClose={closeMenu}
        open={open}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuList dense sx={{ p: 0 }}>
          <MenuItem key="1" onClick={openGiphy}>
            <ListItemIcon>
              <ImageIcon fontSize="small" />
            </ListItemIcon>
            Add Giphy
          </MenuItem>
          <MenuItem key="2" onClick={deleteCard}>
            <ListItemIcon>
              <DeleteIcon fontSize="small" />
            </ListItemIcon>
            Delete card
          </MenuItem>
        </MenuList>
      </Menu>
      <Giphy isGiphyOpen={isGiphyOpen} setIsGiphyOpen={setIsGiphyOpen} setGiphy={handleGiphy} />
    </>
  );
}

export default CardMenu;
