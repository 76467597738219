import React, { useEffect} from 'react';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';

import { socket } from '../config';
import Export from './Export';
import { setBoardId } from '../actions/board';
import Columns from './Columns';
import Template from './Template';

import './Column.css';

import {
  initBoard as initBoardDispatch,
  setBoardTitle,
  updateUsers,
} from '../actions/board';
import { addCard, deleteCard, initCards, moveCard, updateCard as updateCardDispatch } from '../actions/card';
import { addColumn, deleteColumn, initColumns, updateColumnTitle } from '../actions/column';
import { initLikes } from '../actions/like';
import { updateUser } from '../actions/user';
import { useParams } from 'react-router-dom';

// const mapDispatchToProps = (dispatch) => {
//   return {
//     setBoardId: (boardId) => dispatch(setBoardId(boardId)),
//   };
// }

const mapDispatchToProps = (dispatch) => {
  return {
    addCard: (column) => dispatch(addCard(column)),
    addColumn: (column) => dispatch(addColumn(column)),
    deleteCard: (card) => dispatch(deleteCard(card)),
    deleteColumn: (columnId) => dispatch(deleteColumn(columnId)),
    initBoard: (board) => {
      if (board.err === 'BOARD_NOT_FOUND') {
        window.location.href = '/error';
      } else {
        dispatch(initBoardDispatch(board));
      }
    },
    initCards: (cards) => dispatch(initCards(cards)),
    initColumns: (columns) => dispatch(initColumns(columns)),
    initLikes: (likes) =>  dispatch(initLikes(likes)),
    moveCard: (payload) => dispatch(moveCard(payload)),
    setBoardId: (boardId) => dispatch(setBoardId(boardId)),
    setBoardTitle: (boardTitle) => dispatch(setBoardTitle(boardTitle)),
    updateCard: (payload) => dispatch(updateCardDispatch(payload)),
    updateColumnTitle: (payload) => dispatch(updateColumnTitle(payload)),
    updateUser: (user) => dispatch(updateUser(user)),
    updateUsers: (payload) => dispatch(updateUsers(payload)),
  };
};

const Board = ({
  setBoardId, addCard, addColumn, deleteCard, deleteColumn, initBoard, initCards, initColumns, initLikes,
  moveCard, setBoardTitle, updateCard, updateColumnTitle, updateUser, updateUsers,
}) => {
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const boardId = params.id;
  const alreadyNotified = [];

  setBoardId(boardId);

  // Tell the other users that a new user has joined the board
  socket.emit('join', {
    boardId: boardId,
  });

  const notification = (userName) => {
    if (userName.length > 0 && alreadyNotified.indexOf(userName) === -1) {
      enqueueSnackbar(`${userName} just joined the board`, {
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        autoHideDuration: 3000,
        variant: 'info',
      });
      alreadyNotified.push(userName);
    }
  };

  useEffect(() => {
    socket.on('board::init', initBoard);
    socket.on('board::title::update', setBoardTitle);

    socket.on('card::add', addCard);
    socket.on('card::delete', deleteCard);
    socket.on('card::move', moveCard);
    socket.on('card::update', updateCard);

    socket.on('cards::init', initCards);

    socket.on('column::added', addColumn);
    socket.on('column::delete', deleteColumn);
    socket.on('column::title::update', updateColumnTitle);

    socket.on('columns::init', initColumns);

    socket.on('likes::init', initLikes);

    socket.on('user::update', updateUser);
    socket.on('users::join', notification);

    socket.on('users::update', updateUsers);

    return () => {
      socket.off('board::init', initBoard);
      socket.off('board::title::update', setBoardTitle);

      socket.off('card::add', addCard);
      socket.off('card::delete', deleteCard);
      socket.off('card::move', moveCard);
      socket.off('card::update', updateCard);

      socket.off('cards::init', initCards);

      socket.off('column::added', addColumn);
      socket.off('column::delete', deleteColumn);
      socket.off('column::title::update', updateColumnTitle);

      socket.off('columns::init', initColumns);

      socket.off('likes::init', initLikes);

      socket.off('user::update', updateUser);
      socket.off('users::join', notification);

      socket.off('users::update', updateUsers);
    };
  });

  return (
    <Template template="Board">
      <Columns />
      <Export />
    </Template>
  );
};

export default connect(
  null,
  mapDispatchToProps,
)(Board);
