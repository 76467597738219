import React, { useState } from 'react';
import { connect } from 'react-redux';

import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FormControlLabel from '@mui/material/FormControlLabel';
import Popover from '@mui/material/Popover';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';

import { sortCards } from '../../actions/card';

const mapDispatchToProps = (dispatch) => {
  return {
    sortCards: () => dispatch(sortCards()),
  };
}

const Sort = ({ sortCards }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [value, setValue] = useState(localStorage.getItem('sortBy') || 'createdAt');
  const open = Boolean(anchorEl);

  const handleChange = (event) => {
    setValue(event.target.value);
    localStorage.setItem('sortBy', event.target.value);
    sortCards();
    handleClose();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        endIcon={<ExpandMoreIcon />}
        onClick={handleClick}
      >
        Sort by
      </Button>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <RadioGroup aria-label="Sort by" value={value} onChange={handleChange} style={{ padding: '16px' }}>
          <FormControlLabel value="claps" control={<Radio />} label="Likes" />
          <FormControlLabel value="createdAt" control={<Radio />} label="Date" />
          <FormControlLabel value="userName" control={<Radio />} label="User" />
        </RadioGroup>
      </Popover>
    </>
  );
}

export default connect(
  null,
  mapDispatchToProps,
)(Sort);
