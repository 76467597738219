import { INIT_BOARD, SET_BOARD_ID, SET_BOARD_TITLE, UPDATE_USERS} from '../constants/board';
import { CLOSE_DRAWER, OPEN_DRAWER } from '../constants/drawer';
import { TOGGLE_EXPORT } from '../constants/export';
import { TOGGLE_HELP } from '../constants/help';

const initialState = {
  id: '',
  isDrawerOpen: false,
  isExportOpen: false,
  isHelpOpen: false,
  title: '',
  users: [],
};

const boardReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLOSE_DRAWER:
      return {
        ...state,
        isDrawerOpen: false,
      };

    case INIT_BOARD:
      return {
        ...state,
        createdAt: action.board.createdAt,
        title: action.board.title,
      };

    case OPEN_DRAWER:
      return {
        ...state,
        isDrawerOpen: true,
      };

    case SET_BOARD_ID:
      return {
        ...state,
        id: action.boardId,
      };

    case SET_BOARD_TITLE:
      return {
        ...state,
        title: action.boardTitle,
      };

    case TOGGLE_EXPORT:
      return {
        ...state,
        isExportOpen: action.payload,
      };

    case TOGGLE_HELP:
      return {
        ...state,
        isHelpOpen: action.payload,
      };

    case UPDATE_USERS:
      return {
        ...state,
        users: action.users.map((user) => user ? user : 'Anonymous' ),
      };

    default:
      return state;
  }
}

export default boardReducer;
