import { INIT_LIKES } from '../constants/like';

const initialState = {
};

const likeReducer = (state = initialState, action) => {
  // let clonedState;

  switch (action.type) {
    case INIT_LIKES:
      return action.likes;

    default:
      return state;
  }
}

export default likeReducer;
