const startNewBoard = () => {
  fetch('/api/new')
    .then((response) => {
      return response.json();
    })
    .then((board) => {
      // Force reload of a new page
      window.location.href = `/board/${board.id}`;
    });
};

const showMsg = (enqueueSnackbar, variant, text) => {
  enqueueSnackbar(text, {
    variant: variant,
    anchorOrigin: {
      horizontal: 'center',
      vertical: 'bottom',
    }
  });
};

const apiRequest = ({ body, method, url }) => {
  return new Promise((resolve, reject) => {
    const requestOptions = {
      method: method,
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body),
    };

    fetch(url, requestOptions)
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        resolve(res);
      }).catch((err) => {
        reject(err);
      });
      ;
  });
};

const apiGet = (url) => {
  return apiRequest({
    method: 'GET',
    url,
  });
};

const apiPost = (url, body) => {
  return apiRequest({
    method: 'POST',
    url,
    body,
  });
};

export { apiGet, apiPost, apiRequest, showMsg, startNewBoard };
