import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import ClearIcon from '@mui/icons-material/Clear';

import './Card.css';
import { socket } from '../config';
import {
  deleteCard as deleteCardDispatch,
  updateCard as updateCardDispatch,
} from '../actions/card';
import CardMenu from './CardMenu';
import Like from './Like';
import { Box } from '@mui/material';

const mapStateToProps = (state) => {
  return {
    boardId: state.board.id,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteCard: (card) => dispatch(deleteCardDispatch(card)),
    updateCard: (payload) => dispatch(updateCardDispatch(payload)),
  };
}

const SpeedBoardCard = ({ boardId, card, deleteCard, updateCard }) => {
  const [isEdit, toggleEdit] = useState(false);
  const [defaultValue, setDefaultValue] = useState('');
  const [isMouseOver, setIsMouseOver] = useState(false);
  const [giphy, setGiphy] = useState('');

  const deleteGiphy = () => {
    const event = {
      target: {
        value: card.content,
      },
      giphy: {},
    };

    handleEdit(event);
  };

  const handleBlur = (event) => {
    handleEdit(event);
    toggleEdit(false);
  };

  const handleClick = (_event, clickedCard) => {
    setDefaultValue(clickedCard.content);
    toggleEdit(true);
  }

  const handleEdit = (event) => {
    const content = event.target.value.trim();
    const eventGiphy = event.giphy ? event.giphy : {};

    // Delete card if content is empty
    if (content === '') {
      deleteCard(card);
      socket.emit('card::delete', card);

      return;
    }

    const cardContent = {
      boardId: boardId,
      cardId: card.id,
      columnId: card.columnId,
      content,
    };

    if (eventGiphy) {
      cardContent.giphy = eventGiphy;
      setGiphy(eventGiphy);
    }

    updateCard(cardContent);

    socket.emit('card::update', cardContent);
  }

  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.target.blur();
    }
  }

  // @TODO Too many rendering
  useEffect(() => {
    if (card.giphy) {
      setGiphy(card.giphy);
    }
  }, [card.giphy, card.id, isEdit]);

  return (
    <Card className="cardContent" onMouseEnter={() => setIsMouseOver(true)} onMouseLeave={() => setIsMouseOver(false)}>
      <CardContent style={{ paddingBottom: 0 }}>
        {!isEdit &&
          <Typography onClick={(e) => handleClick(e, card)}>
            {card.content}
          </Typography>
        }
        {isEdit &&
          <TextField
            autoFocus
            fullWidth
            InputProps={{
              fullWidth: true,
              style: {
                backgroundColor: '#fff',
              }
            }}
            multiline
            onBlur={handleBlur}
            onChange={(e) => { setDefaultValue(e.target.value); }}
            onFocus={(e) => {
              const value = e.target.value;
              e.target.value = '';
              encodeURI.target.value = value;
            }}
            onKeyPress={(e) => handleKeyPress(e)}
            value={defaultValue}
          />
        }
        {isMouseOver &&
          <CardMenu card={card} handleEdit={handleEdit} setIsMouseOver={setIsMouseOver} />
        }
      </CardContent>
      <CardActions disableSpacing sx={{ flexWrap: 'wrap' }}>
        <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'row' }}>
          <Like card={card} />
        </Box>
        <Typography style={{ marginLeft: 'auto' }} variant="caption" color="textSecondary" className="name">
          {card.userName}
        </Typography>
      </CardActions>
      {Object.keys(giphy).length > 0 &&
        <div style={
          {
            backgroundImage: `url(${giphy.url})`,
            backgroundSize: 'cover',
            lineHeight: 0,
            paddingBottom: `${giphy.height / giphy.width * 100}%`,
            position: 'relative',
            width: '100%',
          }
        }>
          {isMouseOver &&
            <IconButton aria-label="delete" size="small" onClick={deleteGiphy} sx={{ position: 'absolute', top: 8, right: 8, }}>
              <ClearIcon fontSize="inherit" />
            </IconButton>
          }
        </div>
      }
    </Card>
  );
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SpeedBoardCard);
