import useMediaQuery from '@mui/material/useMediaQuery';

function useBreakpoints() {
  const isMobile = useMediaQuery('(max-width: 1279px)');
  const isDesktop = !isMobile;

  return { isMobile, isDesktop };
}

export default useBreakpoints;
