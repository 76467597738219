import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { connect } from 'react-redux';

import Auth from '../Auth';
import Board from '../Board';
// import Listeners from './listeners';
import Login from '../Login';
import Reset from '../Reset';
import Register from '../Register';
// import withAuth from '../withAuth';
import Dashboard from '../Dashboard';
import Profile from '../Profile';
import Help from '../Help/Help';
import ErrorPage from '../Error';

import './App.css';

import { apiGet } from '../../utils';
import { updateUser as updateUserAction } from '../../actions/user';

const mapDispatchToProps = (dispatch) => {
  return {
    updateUser: (user) => dispatch(updateUserAction(user)),
  };
}

const App = ({ updateUser }) => {
  useEffect(() => {
    const getUser = async () => {
      const user = await apiGet('/api/user');
      // if (user.isAnonymous) {
      //   // If the user had a userId before then override it
      //   user.id = localStorage.getItem('userId') || user.id;
      //   localStorage.setItem('userId', user.id);
      // }
      updateUser(user);
    };

    getUser();
  }, [updateUser]);

  return (
    <BrowserRouter>
      <SnackbarProvider maxSnack={3}>
        <Routes>
          <Route path="/board/:id" element={<Board />} />
          <Route path="/login" element={<Login />}>
            <Route path=":id" element={<Login />} />
          </Route>
          <Route path="/error" element={<ErrorPage />} />
          <Route path="/register" element={<Register />} />
          <Route path="/reset" element={<Reset />}>
            <Route path=":code" element={<Reset />} />
          </Route>
          <Route path="/profile" element={<Auth><Profile /></Auth>} />
          <Route path="/dashboard" element={<Auth><Dashboard /></Auth>} />
        </Routes>
        <Help />
      </SnackbarProvider>
    </BrowserRouter>
  );
}

export default connect(
  null,
  mapDispatchToProps,
)(App);
