
import { INIT_BOARD, SET_BOARD_ID, SET_BOARD_TITLE, UPDATE_USERS } from '../constants/board';

const initBoard = (board) => {
  return { type: INIT_BOARD, board };
};

const setBoardId = (boardId) => {
  return { type: SET_BOARD_ID, boardId };
};

const setBoardTitle = (boardTitle) => {
  return { type: SET_BOARD_TITLE, boardTitle };
};

const updateUsers = (users) => {
  return { type: UPDATE_USERS, users };
};

export { initBoard, setBoardId, setBoardTitle, updateUsers }
