
import React from 'react';
import { connect } from 'react-redux';

import Typography from '@mui/material/Typography';

import { socket } from '../config';
import { updateColumnTitle } from '../actions/column';

const mapStateToProps = (state) => {
  return {
    boardId: state.boardId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateColumnTitle: (payload) => dispatch(updateColumnTitle(payload)),
  };
}

const ColumnTitle = ({ column, updateColumnTitle }) => {
  const handleTitle = (event) => {
    if (event.type === 'keypress') {
      if (event.key === 'Enter') {
        event.target.blur();
      };
      return;
    }

    const payload = {
      boardId: column.boardId,
      columnId: column.id,
      title: event.target.textContent,
    };

    updateColumnTitle(payload);
    socket.emit('column::title::update', payload);
  };

  return (
    <Typography
      className="single-line"
      contentEditable
      display="block"
      gutterBottom
      onBlur={handleTitle}
      onKeyPress={handleTitle}
      suppressContentEditableWarning={true}
      variant="overline"
    >
      {column.title}
    </Typography>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ColumnTitle);
