import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Auth = ({ children }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkToken = async () => {
      const res = await fetch('/checkToken');

      if (res.status === 200) {
        setLoading(false);
      } else {
        navigate('/login')
      }
    }

    checkToken();
  }, [navigate]);

  if (loading) {
    return null;
  }

  return children;
}

export default Auth;
