import React, { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';

import Button from '@mui/material/Button';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import Template from './Template';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { showMsg, apiRequest } from '../utils';
import { useNavigate, useParams } from 'react-router-dom';

const Reset = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [code, setCode] = useState('');

  const [values, setValues] = useState({
    password: '',
    showPassword: false,
    email: '',
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickReset = async () => {
    const res = await apiRequest({
      method: 'POST',
      url: '/api/reset',
      body: {
        code: code,
        email: values.email,
        password: values.password,
      },
    });

    if (res.msg === 'email_sent') {
      showMsg(enqueueSnackbar, 'info', 'Click on the link in the email we just sent to reset your password');
    } else if (res.msg === 'password_updated') {
      showMsg(enqueueSnackbar, 'info', 'Your password has been updated');
      navigate('/login');
    } else if (res.msg === 'error') {
      showMsg(enqueueSnackbar, 'error', 'An error occurred. Please try again');
    }
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleClickReset();
      event.target.blur();
   }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (params.code) {
      setCode(params.code);
    }
  }, [params.code]);

  return (
    <Template template="Reset">
      <form className="form">
        {!code &&
          <TextField
            label="Email"
            // id="outlined-margin-normal"
            // defaultValue="Default Value"
            // className={classes.textField}
            // helperText="Some important text"
            margin="normal"
            variant="filled"
            value={values.email}
            onChange={handleChange('email')}
            onKeyDown={handleKeyDown}
          />
        }

        {code &&
          <FormControl variant="filled">
            <InputLabel htmlFor="filled-adornment-password">Password</InputLabel>
            <FilledInput
              id="filled-adornment-password"
              type={values.showPassword ? 'text' : 'password'}
              value={values.password}
              onChange={handleChange('password')}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        }

        <Button variant="contained" color="primary" onClick={handleClickReset}>
          Reset
        </Button>

        <Button href="/login" color="primary">
          Login
        </Button>
      </form>
    </Template>
  );
};

export default Reset;
