import { combineReducers } from 'redux'

import board from './board';
import cards from './card';
import columns from './column';
import likes from './like';
import user from './user';

const rootReducer = combineReducers({
  board: board,
  cards: cards,
  columns: columns,
  likes: likes,
  user: user,
  // users: user,
});

export default rootReducer;
