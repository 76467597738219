import React from 'react';
import { connect } from 'react-redux';
import { Divider, List, ListItem, Typography } from '@mui/material';

import DrawerBoard from './DrawerBoard';
import DrawerDashboard from './DrawerDashboard';
import ListItemDashboard from './ListItemDashboard';
import ListItemProfile from './ListItemProfile';
import ListItemNew from './ListItemNew';
import { closeDrawer as closeDrawerAction } from '../../actions/drawer';
import { toggleHelp } from '../../actions/help';
import pkg from '../../../package.json';

const mapDispatchToProps = (dispatch) => {
  return {
    closeDrawer: () => dispatch(closeDrawerAction()),
    openHelp: () => dispatch(toggleHelp(true)),
  };
};

const DrawerContent = ({ closeDrawer, openHelp, template }) => {
  let customContent;

  switch (template) {
    case 'Board':
      customContent = <DrawerBoard />;
    break;

    case 'Dashboard':
      customContent = <DrawerDashboard />;
    break;

    default:
      customContent = <></>;
  }

  const handleClick = (content) => {
    if (content === 'help') {
      closeDrawer(); // For mobile
      openHelp();
    } else if (content === 'contact') {
      closeDrawer(); // For mobile
      window.open('https://www.armelpingault.com', '_blank');
    }
  }

  return (
    <div className="drawerContent" role="presentation">
      <div style={{ minHeight: '64px' }}></div>
      <List style={{flex: 1}}>
        {customContent}
      </List>
      <List>
        <ListItemProfile />
        <ListItemDashboard />
        <Divider />
        <ListItemNew />
        <Divider />
        <ListItem>
          <Typography variant="caption" onClick={() => handleClick('help')} className="clickable">
            Help
          </Typography>
          &nbsp;·&nbsp;
          <Typography variant="caption" onClick={() => handleClick('contact')} className="clickable">
            Contact
          </Typography>
          &nbsp;·&nbsp;
          <Typography variant="caption" className="clickable">
            {pkg.version}
          </Typography>
        </ListItem>
      </List>
    </div>
  );
};

export default connect(
  null,
  mapDispatchToProps,
)(DrawerContent);
