
import { ADD_COLUMN, DELETE_COLUMN, INIT_COLUMNS, UPDATE_COLUMN_TITLE } from '../constants/column';

const addColumn = (column) => {
  return { type: ADD_COLUMN, column };
};

const deleteColumn = (columnId) => {
  return { type: DELETE_COLUMN, columnId };
};

const initColumns = (columns) => {
  return { type: INIT_COLUMNS, columns };
};

const updateColumnTitle = (payload) => {
  return { type: UPDATE_COLUMN_TITLE, payload };
};

export { addColumn, deleteColumn, initColumns, updateColumnTitle }
