import { useState } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import SearchIcon from '@mui/icons-material/Search';

import { Grid } from '@giphy/react-components';
import { GiphyFetch } from '@giphy/js-fetch-api';

const Giphy = ({ isGiphyOpen, setGiphy, setIsGiphyOpen }) => {
  const [isFirst, setIsFirst] = useState(true);
  const [query, setQuery] = useState('');
  const [value, setValue] = useState('');

  const gf = new GiphyFetch('f53FwcPB4vsIX9dNdb11F4vgf0idr4UI');

  const fetchGifs = (offset) => {
    const options = { offset, limit: 10 };

    if (isFirst) {
      setIsFirst(false);
      return gf.trending(options);
    } else {
      return gf.search(query, options);
    }
  };

  const handleBlur = () => {
    setQuery(value);
  };

  const handleGifClick = (gif) => {
    setGiphy(gif.images.downsized);
    setIsGiphyOpen(false);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <Dialog className="GiphyDialog" onClose={() => setIsGiphyOpen(false)} open={isGiphyOpen}>
      <DialogTitle>
        <FormControl fullWidth>
          <InputLabel htmlFor="standard-adornment-password">Search Giphy</InputLabel>
          <Input
            id="standard-adornment-password"
            type="text"
            value={value}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                setQuery(value);
              }
            }}
            onBlur={handleBlur}
            onChange={handleChange}
            endAdornment={
              <InputAdornment position="end">
                <IconButton>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>
      </DialogTitle>
      <DialogContent>
        <img src="/img/giphy.png" alt="Powered by GIPHY" width="200" height="22" style={{ display: 'flex', margin: '0 auto 16px' }} />
        <Grid key={query} width={552} columns={3} fetchGifs={fetchGifs} noLink={true} onGifClick={handleGifClick} />
      </DialogContent>
    </Dialog>
  );
};

export default Giphy;
