import { ADD_COLUMN, DELETE_COLUMN, INIT_COLUMNS, UPDATE_COLUMN_TITLE } from '../constants/column';

const columnReducer = (state = [], action) => {
  switch (action.type) {
    case ADD_COLUMN:
      return [ ...state, action.column ];

    case DELETE_COLUMN:
      return state.filter((column) => column.id !== action.columnId);

    case INIT_COLUMNS:
      return action.columns;

    case UPDATE_COLUMN_TITLE:
      return state.map((column) => column.id === action.payload.columnId ? { ...column, title: action.payload.title } : column);

    default:
      return state;
  }
}

export default columnReducer;
