import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import Button from '@mui/material/Button';
import FilledInput from '@mui/material/FilledInput';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { updateUser as updateUserAction } from '../actions/user';
import { apiPost, showMsg } from '../utils';
import Template from './Template';

const mapDispatchToProps = (dispatch) => {
  return {
    updateUser: (user) => dispatch(updateUserAction(user)),
  };
}

const Login = ({ updateUser }) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const params = useParams();
  const boardId = params.id;

  const [values, setValues] = useState({
    email: '',
    password: '',
    showPassword: false,
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickLogin = async (event) => {
    const res = await apiPost('/api/authenticate', {
      email: values.email,
      password: values.password,
    });

    if (res.msg === 'login_error') {
      showMsg(enqueueSnackbar, 'error', 'Email or password incorrect');
    } else if (res.msg === 'login_success') {
      updateUser(res);
      if (boardId) {
        // Force reload to make sure the user data are updated
        window.location.href = `/board/${boardId}`;
      } else {
        navigate('/dashboard');
      }
    }
  };

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleClickLogin();
      event.target.blur();
   }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Template template="Login">
      <form className="form">
        <TextField
          label="Email"
          margin="normal"
          variant="filled"
          value={values.email}
          onChange={handleChange('email')}
          onKeyDown={handleKeyDown}
        />

        <FormControl variant="filled" margin="normal">
          <InputLabel htmlFor="filled-adornment-password">Password</InputLabel>
          <FilledInput
            id="filled-adornment-password"
            type={values.showPassword ? 'text' : 'password'}
            value={values.password}
            autoComplete="on"
            onChange={handleChange('password')}
            onKeyDown={handleKeyDown}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </FormControl>

        <Button variant="contained" color="primary" onClick={handleClickLogin}>
          Login
        </Button>

        <Typography variant="caption" className="clickable" onClick={() => navigate('/reset')}>
          Password forgotten?
        </Typography>

        <Button href="/register" color="primary">
          Register
        </Button>
      </form>
    </Template>
  );
};

export default connect(
  null,
  mapDispatchToProps,
)(Login);
