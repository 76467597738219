import React, { useState } from 'react';
import { connect } from 'react-redux';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';

import { socket } from '../config';

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const Unlike = ({ card, like, user }) => {
  const [disabled, setDisabled] = useState(false);

  const handleUnlike = (likeId) => {
    if (disabled) {
      return false;
    }

    setDisabled(true);

    socket.emit('clap::delete', {
      card: card,
      likeId: likeId,
      userId: user.id,
    });
  };

  return (
    <Tooltip title="Unlike" disabled={disabled}>
      <IconButton size="small" style={{opacity: 0.2}} onClick={() => handleUnlike(like.id)}>
        <ThumbUpIcon fontSize="inherit" />
      </IconButton>
    </Tooltip>
  );
}

export default connect(
  mapStateToProps,
)(Unlike);
