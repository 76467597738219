import React from 'react';
import { Box } from '@mui/material';

import AppBar from './AppBar/AppBar';
import DrawerContainer from './Drawer/DrawerContainer';


const Template = ({ children, template }) => {
  return (
    <>
      <AppBar template={template} />
      <DrawerContainer template={template} />
      <Box className="App" sx={{ flexDirection: template === 'Board' ? 'row' : 'column' }}>
        {children}
      </Box>
    </>
  );
};

export default Template;
