import React from 'react';
import { connect } from 'react-redux';
import { DragDropContext } from 'react-beautiful-dnd';

import { socket } from '../config';
import {
  deleteCard as deleteCardDispatch,
  moveCard as moveCardDispatch,
} from '../actions/card';
import Column from './Column';

import './Column.css';

const mapStateToProps = (state) => {
  return {
    boardId: state.board.id,
    columns: state.columns,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteCard: (cardId) => dispatch(deleteCardDispatch(cardId)),
    moveCard: (payload) => dispatch(moveCardDispatch(payload)),
  };
}

const Columns = ({ boardId, columns, deleteCard, moveCard }) => {
  const handleDragEnd = (result) => {
    // If the user cancelled the Drag-n-Drop, don't do anything!
    if (!result.destination && !result.combine) {
      return;
    }

    // If we move a card to another column
    if (result.destination) {
      moveCard({
        result,
      });

      socket.emit('save', {
        boardId,
        result,
      });
    }

    // If we merge 2 cards
    if (result.combine) {
      const fromCardId = result.draggableId;
      const toCardId = result.combine.draggableId;

      // Delete the card from the current board
      deleteCard(fromCardId);

      // Then send the data to all users
      socket.emit('merge', {
        // boardId: boardId,
        fromCardId: fromCardId,
        toCardId: toCardId,
      });
    }
  }

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      {columns.map((column, columnIndex) => (
        <Column key={column.id} column={column} columnIndex={columnIndex} />
      ))}
    </DragDropContext>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Columns);
