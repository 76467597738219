import React from 'react';
import { connect } from 'react-redux';

import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import FileCopyIcon from '@mui/icons-material/FileCopy';

const mapStateToProps = (state) => {
  return {
    board: state.board,
  };
};

const Duplicate = ({ board }) => {
  const handleShare = () => {
    fetch(`/api/duplicate/${board.id}`)
    .then((response) => response.json())
    .then((board) => {
      // Force reload of a new page
      window.open(`/board/${board.id}`);
    });
  }

  return (
    <>
      <ListItem button onClick={handleShare}>
        <ListItemIcon>
          <FileCopyIcon />
        </ListItemIcon>
        <ListItemText primary="Duplicate template" />
      </ListItem>
    </>
  );
};

export default connect(
  mapStateToProps,
)(Duplicate);
