import { ADD_CARD, DELETE_CARD, INIT_CARDS, MOVE_CARD, UPDATE_CARD, SORT_CARDS } from '../constants/card';

const initialState = {
};

const sortBy = (cards) => {
  const CLAPS = 'claps';
  const CREATED_AT = 'createdAt';
  const USERNAME = 'userName';
  const property = localStorage.getItem('sortBy') || CREATED_AT;

  for (let card in cards) {
    if (cards.hasOwnProperty(card)) {
      // Sort by createdAt which is a string (ASC)
      if (property === CREATED_AT || property === USERNAME) {
        cards[card].sort((a, b) => {
          return a[property] < b[property] ? -1 : 1;
        });
      }

      // Sort by claps which is a number (DESC)
      if (property === CLAPS) {
        // Sort cards by claps descending or ascending
        cards[card].sort((a, b) => {
          return b[property] - a[property];
        });
      }
    }
  };
};

const getCard = (state, cardId) => {
  let card;

  // Loop through all the columns
  for (let columnId in state) {
    if (state.hasOwnProperty(columnId)) {
      // Loop through all the cards in the column
      card = state[columnId].find((card) => card.id === cardId);

      if (card) {
        return card;
      }
    }
  }
};

const cardReducer = (state = initialState, action) => {
  let clonedState;

  switch (action.type) {
    case ADD_CARD:
      clonedState = JSON.parse(JSON.stringify(state));

      // Not a big fan to put it here too
      if (!clonedState[action.card.columnId]) {
        clonedState[action.card.columnId] = [];
      }

      clonedState[action.card.columnId].push(action.card);

      // @TODO I don't think that's the right place to put it
      sortBy(clonedState);

      return clonedState;

    case DELETE_CARD:
      clonedState = JSON.parse(JSON.stringify(state));

      // If we provide only the cardId in the argument, then get the card first.
      if (typeof action.card === 'string') {
        action.card = getCard(clonedState, action.card);
      }

      // Loop through all the columns
      for (let columnId in clonedState) {
        if (clonedState.hasOwnProperty(columnId)) {
          // Loop through all the cards in the column
          clonedState[columnId] = clonedState[columnId].filter((card) => card.id !== action.card.id);
        }
      }

      return clonedState;

    // Since we need to reset all the columns, we just create a whole new state
    case INIT_CARDS:
      clonedState = {};

      action.cards.forEach((card) => {
        if (!clonedState[card.columnId]) {
          clonedState[card.columnId] = [];
        }

        clonedState[card.columnId] = [ ...clonedState[card.columnId], card ];
      });

      // @TODO I don't think that's the right place to put it
      sortBy(clonedState);

      return clonedState;

    case MOVE_CARD:
      const fromColumnId = action.payload.result.source.droppableId;
      const toColumnId = action.payload.result.destination.droppableId;

      // If we don't change the column then don't do anything, the card will be automatically sorted by thre strategy
      if (fromColumnId === toColumnId) {
        return state;
      }

      clonedState = JSON.parse(JSON.stringify(state));

      // If we move a card to an empty column then create it first
      clonedState[toColumnId] = clonedState[toColumnId] || [];

      // Add the card to the new place
      const cardToMove = clonedState[fromColumnId].filter((card) => card.id === action.payload.result.draggableId);

      // Return an array so we have to take the first and unique element out
      clonedState[toColumnId].push(cardToMove[0]);

      // Delete the card from the column
      clonedState[fromColumnId] = clonedState[fromColumnId].filter((card) => card.id !== action.payload.result.draggableId);

      // @TODO I don't think that's the right place to put it
      sortBy(clonedState);

      return clonedState;

    case SORT_CARDS:
      clonedState = JSON.parse(JSON.stringify(state));
      sortBy(clonedState);

      return clonedState;

    // @TODO Seems like it's updating ALL cards and not only the one we want
    case UPDATE_CARD:
      clonedState = JSON.parse(JSON.stringify(state));

      // Loop through all the columns
      // @TODO Performance issue?
      for (let columnId in clonedState) {
        if (clonedState.hasOwnProperty(columnId)) {
          // Loop through all the cards in the column
          clonedState[columnId].forEach((card) => {
            if (card.id === action.payload.cardId) {
              // Update the content
              if (action.payload.content) {
                card.content = action.payload.content;
              }
              // Update the Giphy
              if (action.payload.giphy) {
                card.giphy = action.payload.giphy;
              }
              // Update the claps
              if (action.payload.claps >= 0) {
                card.claps = action.payload.claps;
              }
            }
          });
        }
      }

      // @TODO I don't think that's the right place to put it
      sortBy(clonedState);

      return clonedState;

    default:
      return state;
  }
}

export default cardReducer;
