import React from 'react';
import { connect } from 'react-redux';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardIcon from '@mui/icons-material/Keyboard';

import './Help.css';

import { toggleHelp } from '../../actions/help';

const mapStateToProps = (state) => {
  return {
    isHelpOpen: state.board.isHelpOpen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeHelp: () => dispatch(toggleHelp(false)),
  };
}

const Help = ({ closeHelp, isHelpOpen }) => {
  return (
    <Dialog className="HelpDialog" onClose={closeHelp} open={isHelpOpen}>
      <DialogTitle>
        Help
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <span>
            <EditIcon></EditIcon>
          </span>
          <span style={{marginLeft: '15px'}}>
            Click on a card to edit its content
          </span>
        </DialogContentText>
        <DialogContentText>
          <span>
            <DragHandleIcon></DragHandleIcon>
          </span>
          <span style={{marginLeft: '15px'}}>
            Drag-n-Drop cards as you wish
          </span>
        </DialogContentText>
        <DialogContentText>
          <span>
            <KeyboardIcon></KeyboardIcon>
          </span>
          <span style={{marginLeft: '15px'}}>
            Shift + Enter to create a line break or a new line
          </span>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Help);
