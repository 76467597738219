import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useSnackbar } from 'notistack';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';

import Template from './Template';
import { updateUser } from '../actions/user';

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUser: (user) => dispatch(updateUser(user)),
  };
};

const Profile = ({ updateUser, user }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [values, setValues] = useState({ email: '', name: '' });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleSave = () => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(values),
    };

    fetch('/api/user', requestOptions)
      .then((response) => response.json())
      .then((res) => {

        if (res.msg === 'name_invalid') {
          enqueueSnackbar('Your name is not valid.', {
            variant: 'error',
            anchorOrigin: {
              horizontal: 'center',
              vertical: 'bottom',
            }
          });
        }

        if (res.msg === 'email_invalid') {
          enqueueSnackbar('Your email is not formatted correctly.', {
            variant: 'error',
            anchorOrigin: {
              horizontal: 'center',
              vertical: 'bottom',
            }
          });
        }

        if (res.msg === 'profile_updated') {
          enqueueSnackbar('Profile updated successfully.', {
            variant: 'success',
            anchorOrigin: {
              horizontal: 'center',
              vertical: 'bottom',
            }
          });
          updateUser(res);
        }
      });
  };

  const handleLogout = () => {
    setIsLoggingOut(true);

    // The line below doesn't seem necessary anymore
    // document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:01 GMT;';

    // Legacy code that was using localStorage to save the userName for non-registered users
    localStorage.removeItem('userName');

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
    };

    fetch('/api/logout', requestOptions)
      .then((response) => response.json())
      .then(() => {
        window.location.href = '/login';
      });
  };

  useEffect(() => {
    setValues(user);
  }, [user]);

  return (
    <Template template="Profile">
      <div className="form">
        <TextField
          label="Username"
          value={values.name}
          variant="filled"
          onChange={handleChange('name')}
        />

        <TextField
          label="Email"
          value={values.email}
          variant="filled"
          onChange={handleChange('email')}
        />

        <Button variant="contained" color="primary" onClick={handleSave}>
          Update
        </Button>

        <Button color="primary" onClick={handleLogout} disabled={isLoggingOut}>
          {isLoggingOut ? 'Logging out...' : 'Logout'}
        </Button>
      </div>
    </Template>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Profile);
